<div class="card custom-box my-3 border-0 ">
  <div class="card-body">
    <div class="row mt-3">
      <div class="text-center">
        <img src="assets/images/logo_acfs.png" class="img-card">
      </div>
    </div>
    <div class="row mt-5">
      <div class="ps-5 pe-5 text-start message-register">
        <h3 class="text-center">
          นโยบายการคุ้มครองข้อมูลส่วนบุคคล (Privacy Policy)
          <br>
          สำนักงานมาตรฐานสินค้าเกษตรและอาหารแห่งชาติ
        </h3>
        <hr class="m-5">
        <span style="margin-left:3em">
          สำนักงานมาตรฐานสินค้าเกษตรและอาหารแห่งชาติ (มกอช.) ตระหนักถึงความสำคัญของข้อมูลส่วนบุคคลและข้อมูลอื่น
          อันเกี่ยวกับท่าน เพื่อให้ท่านสามารถเชื่อมั่นได้ว่า มกอช. มีความโปร่งใสและความรับผิดชอบในการเก็บรวบรวม
          ใช้หรือเปิดเผยข้อมูลของท่านตามพระราชบัญญัติคุ้มครองข้อมูล
          ส่วนบุคคล พ.ศ.๒๕๖๒ รวมถึงกฎหมายอื่นที่เกี่ยวข้องนโยบายการคุ้มครองข้อมูลส่วนบุคคลนี้จึงได้ถูกจัดทำขึ้นเพื่อชี้แจงแก่ท่านถึงรายละเอียดเกี่ยวกับการเก็บรวบรวม
          ใช้หรือเปิดเผย โดยรวมเรียกว่า “ประมวลผล” ข้อมูลส่วนบุคคลซึ่งดำเนินการโดย มกอช. รวมถึงเจ้าหน้าที่และบุคคลที่เกี่ยวข้องผู้ดำเนินการแทนหรือในนาม
          ของ มกอช.โดยมีเนื้อหาสาระดังต่อไปนี้
        </span>
        <br>
        <br>
        <h4>
          การเก็บรวบรวมข้อมูลส่วนบุคคล
        </h4>
        <span style="margin-left:3em">
          เพื่อความสะดวกในการให้บริการแก่ผู้ใช้บริการทุกท่านที่เข้ามาใช้บริการเว็บไซต์ระบบตามสอบสินค้าเกษตรบนระบบคลาวด์
          (QR Trace on Cloud) ทางเว็บไซต์จึงได้จัดเก็บรวบรวมข้อมูลส่วนบุคคลของท่านไว้ เช่น ชื่อ-นามสกุล ชื่อหน่วยงาน ที่อยู่
          เลขประจำตัวประชาชน ตำแหน่ง เบอร์โทรศัพท์ อีเมล รูปภาพ เป็นต้น
        </span>
        <br>
        <br>
        <h4>
          การใช้ข้อมูลส่วนบุคคล
        </h4>
        <span style="margin-left:3em">
          ๑. มกอช. จะใช้ข้อมูลส่วนบุคคลของท่านเพียงเท่าที่จำเป็น เพื่อใช้ในการลงทะเบียนเป็นผู้ใช้งานในระบบ และเพื่อใช้ระบุแหล่งที่มาของสินค้า เท่านั้น
        </span>
        <br>
        <span style="margin-left:3em">
          ๒. มกอช. ขอรับรองว่าจะไม่นำข้อมูลส่วนบุคคลของท่านที่ มกอช. ได้เก็บรวบรวมไว้ไปขายหรือเผยแพร่ให้กับบุคคลภายนอกโดยเด็ดขาด
        </span>
        <br>
        <span style="margin-left:3em">
          ๓. ในกรณีที่ มกอช. ได้ว่าจ้างหน่วยงานอื่นเพื่อให้ดำเนินการเกี่ยวกับข้อมูลส่วนบุคคลของท่าน มกอช. จะกำหนดให้หน่วยงานที่ได้ว่าจ้างให้ดำเนินการดังกล่าว
          เก็บรักษาความลับ และความปลอดภัยของข้อมูลส่วนบุคคลของท่าน และกำหนดข้อห้ามมิให้มีการนำข้อมูลส่วนบุคคลดังกล่าว
          ไปใช้นอกเหนือจากกิจกรรมหรือกิจการของ มกอช.
        </span>
        <br>
        <br>
        <h4>
          การปฏิบัติตามนโยบายคุ้มครองข้อมูลส่วนบุคคลและการติดต่อกับ มกอช.
        </h4>
        <span style="margin-left:3em">
          ในกรณีที่ท่านมีข้อสงสัย ข้อเสนอแนะ หรือข้อติชมใด ๆ เกี่ยวกับนโยบายการคุ้มครองข้อมูลส่วนบุคคล หรือการปฏิบัติตามนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ มกอช.
          ยินดีที่จะตอบข้อสงสัย รับฟังข้อเสนอแนะ และคำติชมทั้งหลาย อันจะเป็นประโยชน์ต่อการปรับปรุงการให้บริการของ มกอช.
          ต่อไป โดยท่านสามารถติดต่อกับ มกอช. ตามที่อยู่ดังนี้
        </span>
        <br>
        <span style="margin-left:3em">
          สำนักงานมาตรฐานสินค้าเกษตรและอาหารแห่งชาติ เลขที่ ๕๐ ถนนพหลโยธิน แขวงลาดยาว เขตจตุจักร กรุงเทพมหานคร ๑๐๙๐๐
        </span>
        <br>
        <span style="margin-left:3em">
         หมายเลขโทรศัพท์ ๐๒-๕๖๑-๒๒๗๗
        </span>
        <br>
        <br>
      </div>
    </div>
    <div class="row mt-5 mb-4">
      <div class="text-end">
        <button type="button"
                class="btn btn-custom btn-custom-theme me-2"
                (click)="next()">
          รับทราบ
        </button>
        <button type="button"
                class="btn btn-custom btn-light me-2"
                (click)="back()">
          ยกเลิก
        </button>
      </div>
    </div>
  </div>
</div>
