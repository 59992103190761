import {Component, inject, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'app-manager-user-modal',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './manager-user-modal.component.html',
  styleUrl: './manager-user-modal.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ManagerUserModalComponent {
  prefix = '';
  public activeModal = inject(NgbActiveModal);

  save() {
  }
}
