import {Component, inject} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {NgbModal, NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../shared/not-found/not-found.component";
import {ReactiveFormsModule} from "@angular/forms";
import {Page} from '../../../generated-model/model';

@Component({
  selector: 'app-manager-qrcode',
  standalone: true,
    imports: [
        DecimalPipe,
        NgForOf,
        NgIf,
        NgbPagination,
        NotFoundComponent,
        ReactiveFormsModule
    ],
  templateUrl: './manager-qrcode.component.html',
  styleUrl: './manager-qrcode.component.scss'
})
export class ManagerQrcodeComponent {
  pageData: Page<any> = {size: 10};
  private modalService = inject(NgbModal);

  search(page: number = 1) {
  }
}
