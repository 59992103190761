import {Component} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {NgbPagination} from "@ng-bootstrap/ng-bootstrap";
import {NotFoundComponent} from "../../shared/not-found/not-found.component";
import {Page} from '../../../generated-model/model';
import {ReactiveFormsModule} from "@angular/forms";
import {GtNgDatepickerModule} from '../../shared/gt-ng-datepicker/gt-ng-datepicker.module';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [
    DecimalPipe,
    NgForOf,
    NgIf,
    NgbPagination,
    NotFoundComponent,
    ReactiveFormsModule,
    GtNgDatepickerModule
  ],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent {
  pageData: Page<any> = {size: 10};

  search(page: number = 1) {
  }
}
