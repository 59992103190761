<div class="card custom-card my-3 border-0">
  <div class="card-body">
    <div class="row mb-3">
      <label class="col-form-label col-auto">
        ข้อมูลทั่วไป
      </label>
    </div>
    <hr>
    <div class="row mt-5 mb-1 ps-4 pe-4">
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">ชื่อ / Name</label>
      <label class="col-xl-3 col-sm-12 mb-sm-3">ทดสอบ</label>
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">นามสกุล / Surname</label>
      <label class="col-xl-3 col-sm-12 mb-sm-3">ทดสอบ</label>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">เบอร์ติดต่อ / Telephone</label>
      <label class="col-xl-3 col-sm-12 mb-sm-3">099-999-9999</label>
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">อีเมล / e-Mail</label>
      <label class="col-xl-3 col-sm-12 mb-sm-3">test&#64;gmail.com</label>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">ชื่อผู้ใช้งาน / Username</label>
      <label class="col-xl-3 col-sm-12 mb-sm-3">somchai_jaidee</label>
      <label class="col-xl-3 col-sm-12 ui-require text-xl-end text-sm-start">เลือกประเภทผู้ใช้งาน /
        Business Type</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <select class="form-select">
          <option [ngValue]="undefined">--- กรุณาเลือก ---</option>
          <option [ngValue]="undefined">บริษัท</option>
          <option [ngValue]="undefined">เกษตรกร</option>
          <option [ngValue]="undefined">กลุ่มเกษตรกร</option>
          <option [ngValue]="undefined">วิสาหกิจชุมชน</option>
          <option [ngValue]="undefined">กลุ่มสหกรณ์</option>
        </select>
      </div>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <label class="col-xl-3 col-sm-12 ui-require text-xl-end text-sm-start">เลือกประเภทผู้ประกอบการ / Select Juristic
        Type</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <select class="form-select">
          <option [ngValue]="undefined">--- กรุณาเลือก ---</option>
          <option [ngValue]="undefined">บุคคลธรรมดา</option>
          <option [ngValue]="undefined">นิติบุคคล</option>
        </select>
      </div>
      <label class="col-xl-3 col-sm-12 ui-require text-xl-end text-sm-start">รหัสผู้ประกอบการ / Entrepreneur
        No. </label>
      <div class="col-xl-3 col-sm-12 mt-sm-2 mb-sm-2">
        <input class="me-2 form-check-input"
               name="number-company"
               [ngModel]="haveCode"
               [value]="true"
               type="radio"
               (ngModelChange)="changeValue($event)"/>
        <label class="me-5">มีรหัส</label>
        <input class="me-2 form-check-input"
               name="number-company"
               [ngModel]="haveCode"
               [value]="false"
               type="radio"
               (ngModelChange)="changeValue($event)"/>
        <label class="me-5">ไม่มีรหัส</label>
      </div>
      <div class="d-xl-none col-sm-12 mb-sm-3">
        <input type="text" name="companyCode" [disabled]="!haveCode"
               placeholder="กรุณาระบุ ( กรณีมีรหัส )" class="form-control">
      </div>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <label class="col-xl-3 col-sm-12 ui-require text-xl-end text-sm-start">เลขทะเบียนนิติบุคคล / Juristic ID.</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <input type="text" class="form-control">
      </div>
      <div class="col-xl-3 d-xl-flex d-sm-none"></div>
      <div class="col-xl-3 d-xl-flex d-sm-none">
        <input type="text" name="companyCode" [disabled]="!haveCode"
               placeholder="กรุณาระบุ ( กรณีมีรหัส )" class="form-control">
      </div>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <label class="col-xl-3 col-sm-12 ui-require text-xl-end text-sm-start">ชื่อกิจการ/บริษัท / Business/Company
        Name</label>
      <div class="col-xl-9 col-sm-12 mb-sm-3">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row mb-5 ps-4 pe-4">
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">หมายเลข GLN (ถ้ามี) / Global Location Number</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <input type="text" class="form-control">
      </div>
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">Website (ถ้ามี)</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <input type="text" placeholder="www.example.com" class="form-control">
      </div>
    </div>
    <hr>
    <div class="row mb-3">
      <label class="col-form-label col-auto">
        ข้อมูลที่อยู่ / Address
      </label>
    </div>
    <hr>
    <div class="row mt-5 mb-1 ps-4 pe-4">
      <label class="col-xl-3 col-sm-12 ui-require text-xl-end text-sm-start">ที่อยู่ / Address</label>
      <div class="col-xl-9 col-sm-12 mb-sm-3">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">หมู่ที่ / Village No.</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <input type="text" class="form-control">
      </div>
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">ซอย / Street</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">ถนน / Road</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <input type="text" class="form-control">
      </div>
      <label class="col-xl-3 col-sm-12 ui-require text-xl-end text-sm-start">จังหวัด / Province</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <select class="form-select">
          <option [ngValue]="undefined">--- กรุณาเลือก ---</option>
        </select>
      </div>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <label class="col-xl-3 col-sm-12 ui-require text-xl-end text-sm-start">อำเภอ/เขต / District</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <select class="form-select">
          <option [ngValue]="undefined">--- กรุณาเลือก ---</option>
        </select>
      </div>
      <label class="col-xl-3 col-sm-12 ui-require text-xl-end text-sm-start">ตำบล/แขวง / Sub-District</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <select class="form-select">
          <option [ngValue]="undefined">--- กรุณาเลือก ---</option>
        </select>
      </div>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <label class="col-xl-3 col-sm-12 ui-require text-xl-end text-sm-start">รหัสไปษณีย์ / Postal Code</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <input type="text" class="form-control">
      </div>
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">แฟกซ์ / Fax</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <label class="col-xl-3 col-sm-12 ui-require text-xl-end text-sm-start">พิกัดทางภูมิศาสตร์ ละติจูด /
        Latitude</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <input type="text" class="form-control">
      </div>
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">ลองจิจูด / Longitude</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row mb-5 ps-4 pe-4">
      <div class="d-xl-flex col-xl-3 d-sm-none"></div>
      <div class="col-xl-6 col-sm-12 text-xl-start text-sm-center">
        <div>
          <button type="button"
                  class="btn btn-custom btn-custom-theme me-2">
            <i class="bi bi-geo-alt-fill me-2"></i>
            ตำแหน่งปัจจุบัน
          </button>
          <button type="button" (click)="goToGoogleMap()"
                  class="btn btn-custom btn-custom-theme me-2">
            <i class="bi bi bi-map-fill me-2"></i>
            เปิดด้วย Google Map
          </button>
        </div>
      </div>
      <div class="d-xl-flex col-xl-3 d-sm-none"></div>
    </div>
    <hr>
    <div class="row mb-3">
      <label class="col-form-label col-auto">
        ข้อมูลผู้ประกอบการ / Entrepreneur
      </label>
    </div>
    <hr>
    <div class="row mt-5 mb-1 ps-4 pe-4">
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">รูปผู้ประกอบการ / Entrepreneur Image</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <div ng2FileDrop
             [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
             (fileOver)="fileOverBase($event)"
             [uploader]="uploader"
             class="well my-drop-zone text-muted">
          <br>
          <i class="bi bi-upload" style="font-size: 7.5rem"></i>
          <br>
          Drag file here or
          <button type="button" (click)="fileInput.click()" class="btn btn-custom btn-light">Browse
          </button>
          <input type="file" #fileInput ng2FileSelect [uploader]="uploader" style="display: none"/>
          <div *ngIf="uploader?.queue?.length"
               class="text-primary mt-2 small">{{ uploader?.queue[0]?.file?.name }}
          </div>
        </div>
      </div>
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">วีดีโอผู้ประกอบการ / Entrepreneur Video</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">จำนวนสมาชิก / Membership</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">จำนวนแปลง/ฟาร์ม / Number of Plots/Farm</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <input type="text" class="form-control">
      </div>
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">จำนวนพื้นที่ปลูกทั้งหมด / Total Planting Area</label>
      <div class="col-xl-3 col-sm-12 mb-sm-3">
        <input type="text" class="form-control">
      </div>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <label class="col-xl-3 col-sm-12 text-xl-end text-sm-start">สถานที่ส่งจำหน่าย / Place Of Distribution</label>
      <div class="col-xl-9 col-sm-12 mb-sm-3">
        <input class="me-2 form-check-input" type="checkbox"/><label class="me-5">Tops supermarket</label>
        <input class="me-2 form-check-input" type="checkbox"/><label class="me-5">MAKRO</label>
        <input class="me-2 form-check-input" type="checkbox"/><label class="me-5">Big C</label>
        <input class="me-2 form-check-input" type="checkbox"/><label class="me-5">Lotus's</label>
        <input class="me-2 form-check-input" type="checkbox"/><label class="me-5">GO Wholesale</label>
      </div>
      <div class="d-xl-flex col-xl-3 d-sm-none"></div>
      <div class="col-xl-9 col-sm-12 mb-sm-3">
        <div class="input-group">
          <input [ngModel]="isShopEtc"
                 (click)='toggleShopEtc()'
                 class="me-2 form-check-input" type="checkbox"/>
          <label class="me-3">
            อื่น ๆ / ETC.
          </label>
          <input placeholder="กรุณาระบุ" [disabled]="!isShopEtc" type="text" class="form-control">
        </div>
      </div>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <label class="col-xl-3 col-sm-6 text-xl-end text-sm-start">มาตรฐานระบบการผลิต / Production System
        Standards</label>
      <div class="d-xl-flex col-xl-3 d-sm-none"></div>
      <label class="col-xl-3 col-sm-3">เลขที่ใบรับรอง / Certificate Number</label>
      <label class="col-xl-3 col-sm-3">วันที่ได้รับใบรับรอง / Date Of Receipt Of Certificate</label>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <div class="d-xl-flex col-xl-3 d-sm-none"></div>
      <div class="col-xl-3 col-sm-6 text-start">
        <input class="me-2 form-check-input" type="checkbox"/><label>Primary GMP</label>
      </div>
      <div class="col-xl-3 col-sm-3">
        <input type="text" class="form-control">
      </div>
      <div class="col-xl-3 col-sm-3">
        <gt-ng-datepicker></gt-ng-datepicker>
      </div>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <div class="d-xl-flex col-xl-3 d-sm-none"></div>
      <div class="col-xl-3 col-sm-6 text-start">
        <input class="me-2 form-check-input" type="checkbox"/><label>GMP</label>
      </div>
      <div class="col-xl-3 col-sm-3">
        <input type="text" class="form-control">
      </div>
      <div class="col-xl-3 col-sm-3">
        <gt-ng-datepicker></gt-ng-datepicker>
      </div>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <div class="d-xl-flex col-xl-3 d-sm-none"></div>
      <div class="col-xl-3 col-sm-6 text-start">
        <input class="me-2 form-check-input" type="checkbox"/><label>HACCP</label>
      </div>
      <div class="col-xl-3 col-sm-3">
        <input type="text" class="form-control">
      </div>
      <div class="col-xl-3 col-sm-3">
        <gt-ng-datepicker></gt-ng-datepicker>
      </div>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <div class="d-xl-flex col-xl-3 d-sm-none"></div>
      <div class="col-xl-3 col-sm-6 text-start">
        <input class="me-2 form-check-input" type="checkbox"/><label>อย. / FDA</label>
      </div>
      <div class="col-xl-3 col-sm-3">
        <input type="text" class="form-control">
      </div>
      <div class="col-xl-3 col-sm-3">
        <gt-ng-datepicker></gt-ng-datepicker>
      </div>
    </div>
    <div class="row mb-1 ps-4 pe-4">
      <div class="d-xl-flex col-xl-3 d-sm-none"></div>
      <div class="col-xl-3 col-sm-6 text-start">
        <div class="input-group">
          <input [ngModel]="isStdEtc"
                 (click)='toggleStdEtc()'
                 class="me-2 form-check-input"
                 type="checkbox"/>
          <label class="me-3">
            อื่นๆ / ETC.
          </label>
          <input type="text" placeholder="กรุณาระบุ" [disabled]="!isStdEtc" class="form-control">
        </div>
      </div>
      <div class="col-xl-3 col-sm-3">
        <input type="text" [disabled]="!isStdEtc" class="form-control">
      </div>
      <div class="col-xl-3 col-sm-3">
        <gt-ng-datepicker [disabled]="!isStdEtc"></gt-ng-datepicker>
      </div>
    </div>
    <div class="row mb-5 mt-5">
      <div class="col-12 text-start">
        <div class="text-center">
          <button type="button"
                  class="btn btn-custom btn-custom-theme me-2">
            <i class="bi bi-floppy-fill me-2"></i>
            บันทึก
          </button>
          <button type="button"
                  class="btn btn-custom btn-light me-2">
            <i class="bi bi-x-lg me-2"></i>
            ยกเลิก
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
